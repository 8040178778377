import React from "react"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Button from "../../components/button"
import Constrain from "../../components/constrain"
import Heading from "../../components/heading"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const NewsletterAbmeldung = () => (
  <Layout backdrop="newsletter">
    <Seo
      title="Vom Newsletter abmelden"
      description="Melden Sie sich jetzt zu unserem Newsletter an"
    />
    <Stack space={[12, 24]}>
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Newsletter",
              link: "/newsletter",
            },
            {
              title: "Abmelden",
              link: "/newsletter/abmeldung",
            },
          ]}
        />
        <PageTitle>Neues aus dem Hölderlinturm</PageTitle>
        <Constrain>
          <Box bg="muted" p={[6, 12]}>
            <Stack space={8}>
              <Heading as="h2" level={4}>
                Newsletter Abmeldung
              </Heading>
              <Paragraph>
                Schade, dass Sie keine weiteren Nachrichten von uns erhalten
                möchten. Bitte klicken Sie unten auf »Jetzt abmelden«, um die
                Abmeldung abzuschließen.
              </Paragraph>
              <form method="post" action="#">
                <Button type="submit">Jetzt abmelden</Button>
              </form>
            </Stack>
          </Box>
        </Constrain>
      </Stack>
    </Stack>
  </Layout>
)

export default NewsletterAbmeldung
